import { checkConsentForPurpose } from "./integration/didomi";
import { getArticleIgnoreList } from "./helpers/getTeaserIgnoreList";
async function autoTeaser() {
  const autoteaser = window.document.querySelector(".teaser[data-autoteaser=true]");
  if (!autoteaser) return;
  const hasConsent = await new Promise(resolve => {
    setTimeout(() => resolve(false), 1000);
    checkConsentForPurpose("marketing", resolve);
  });
  const dataPosition = autoteaser.dataset.position;
  const sectionId = autoteaser.dataset.sectionid;
  const content = document.getElementById("content");
  const teasers = content.querySelectorAll(".teaser");
  const articleIgnoreList = getArticleIgnoreList(teasers, autoteaser);
  const url = new URL("/poof", window.location.origin);
  url.searchParams.append("pathname", window.location.pathname);
  url.searchParams.append("section", sectionId);
  url.searchParams.append("hasConsent", hasConsent);
  url.searchParams.append("location", dataPosition);
  const response = await fetch(url.href, {
    credentials: "same-origin",
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      ignoreList: articleIgnoreList
    }),
    cache: "no-store"
  });
  if (response.status !== 200) {
    autoteaser.classList.remove("visibility-hidden");
    return;
  }
  const personalizedContent = await response.text();
  const template = document.createElement("template");
  template.innerHTML = personalizedContent;
  autoteaser.replaceChildren(...template.content.querySelector(".teaser").children);
  autoteaser.classList.remove("visibility-hidden");
}
autoTeaser();